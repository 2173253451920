import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { LessonsLearned } from '@oui/app-core/src/components/LessonsLearned/LessonsLearned';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';

import { MyProgress } from '@src/components/MyProgress/MyProgress';
import { Profile } from '@src/components/Profile/Profile';

export function UserProfile() {
  const safeArea = useSafeAreaInsets();
  return (
    <ScrollView
      bottomOverflowColor="white"
      style={{ flex: 1 }}
      contentContainerStyle={{
        paddingTop: safeArea.top + 20,
        paddingBottom: safeArea.bottom + 150,
      }}
      testID="Profile_scrollView"
    >
      <Profile />
      <LessonsLearned />
      <MyProgress />
    </ScrollView>
  );
}
