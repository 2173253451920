import { useApolloClient } from '@apollo/client';
import { HeaderBackButton } from '@react-navigation/elements';
import { StackNavigationOptions } from '@react-navigation/stack';
import * as Sentry from '@sentry/core';
import { useEffect } from 'react';
import { Platform } from 'react-native';

import { registerActivityDiaryScreens } from '@oui/activity-diary';
import { HeaderButtons, HeaderItem } from '@oui/app-core/src/components/HeaderButtons';
import { RoundedSectionNavigationOptions } from '@oui/app-core/src/components/RoundedSection';
import { DEFAULT_HEADER_MODE, SESSION_TIMEOUT } from '@oui/app-core/src/constants';
import { useCurrentUser } from '@oui/app-core/src/hooks/useCurrentUser';
import { usePersistedState } from '@oui/app-core/src/hooks/usePersistedState';
import { createSessionTimeoutStackNavigator } from '@oui/app-core/src/lib/createSessionTimeoutNavigator';
import { useDefaultHeaderHeight } from '@oui/app-core/src/lib/getDefaultHeaderHeight';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { setDeviceInfo } from '@oui/app-core/src/lib/setDeviceInfo';
import Auth from '@oui/app-core/src/screens/Auth';
import { ContactsPicker } from '@oui/app-core/src/screens/ContactsPicker';
import { Conversation } from '@oui/app-core/src/screens/Conversation';
import { CreateTestUser } from '@oui/app-core/src/screens/CreateTestUser';
import { EditLessonsLearned } from '@oui/app-core/src/screens/EditLessonsLearned';
import LocalAuthenticationPrompt from '@oui/app-core/src/screens/LocalAuthenticationPrompt';
import { QuizSet } from '@oui/app-core/src/screens/QuizSet';
import { TermsAndPrivacy } from '@oui/app-core/src/screens/TermsAndPrivacy';
import { useTheme } from '@oui/app-core/src/styles';
import { registerBingeEatingMyPlanScreens } from '@oui/binge-eating-my-plan';
import { registerEatingLogScreens } from '@oui/eating-log';
import { registerHopeKitScreens } from '@oui/hope-kit';
import { registerRelaxDiaryScreens } from '@oui/relax-diary';
import { registerSleepDiaryScreens } from '@oui/sleep-diary';

import { TabNavigator } from '@src/components/TabNavigator';
import { Account } from '@src/screens/Account';
import { Confidentiality } from '@src/screens/Confidentiality';
import { FinishRegistering } from '@src/screens/FinishRegistering';
import { GetStarted } from '@src/screens/GetStarted';
import { MyProgress } from '@src/screens/MyProgress';
import { Welcome } from '@src/screens/Welcome';
import { RootStackParamList } from '@src/types/navigation';

const Stack = createSessionTimeoutStackNavigator<RootStackParamList>(SESSION_TIMEOUT);
export const RootNavigator = () => {
  const { scheme, Color, theme } = useTheme();
  const { isLoggedIn } = useCurrentUser();
  const defaultHeaderHeight = useDefaultHeaderHeight();
  const { $t } = useI18n();
  const apollo = useApolloClient();

  const [hasSeenLocalAuthenticationPrompt] = usePersistedState(
    'SeenLocalAuthenticationPrompt',
    false,
  );

  useEffect(() => {
    if (isLoggedIn) {
      setDeviceInfo(apollo).catch(Sentry.captureException);
    }
  }, [apollo, isLoggedIn]);

  return (
    <Stack.Navigator
      {...Platform.select({ web: { cardStyle: { flex: 1 } } })} // So web screens grow properly
      screenOptions={() => {
        const options: StackNavigationOptions = {
          headerStyle: {
            height: defaultHeaderHeight,
          },
          headerBackTestID: 'header-back',
          headerTruncatedBackTitle: $t({ id: 'App_backButtonLabel', defaultMessage: 'Back' }),
          headerTintColor: theme.color.primary100,
          headerTitleStyle: { color: Color.heading, fontFamily: 'OpenSansSemiBold' },
          headerMode: DEFAULT_HEADER_MODE,
        };
        return options;
      }}
    >
      {isLoggedIn ? (
        <>
          {hasSeenLocalAuthenticationPrompt ? null : (
            <Stack.Screen
              name="LocalAuthenticationPrompt"
              component={LocalAuthenticationPrompt}
              options={{ headerShown: false }}
            />
          )}
          <Stack.Screen
            name="home"
            component={TabNavigator}
            options={{ headerShown: false, title: '' }}
          />
          <Stack.Screen name="TermsAndPrivacy" component={TermsAndPrivacy} />
          <Stack.Screen
            name="Confidentiality"
            component={Confidentiality}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="FinishPatientRegistration"
            component={FinishRegistering}
            options={{ headerShown: false }}
          />
          <Stack.Screen name="GetStarted" component={GetStarted} options={{ headerShown: false }} />

          {registerRelaxDiaryScreens(Stack)}
          {registerHopeKitScreens(Stack)}
          {registerBingeEatingMyPlanScreens(Stack)}
          {registerActivityDiaryScreens(Stack)}
          {registerSleepDiaryScreens(Stack)}

          <Stack.Screen
            name="Conversation"
            component={Conversation}
            options={({ route, navigation }) => ({
              headerBackTitleVisible: false,
              title:
                route.params?.title ??
                route.params?.ID ??
                $t({ id: 'Conversation_title', defaultMessage: 'Conversation' }),
              headerRight: ({ tintColor }) => (
                <HeaderButtons>
                  <HeaderItem
                    iconName="eating-log"
                    color={tintColor}
                    title=""
                    onPress={() => navigation.navigate('EatingLog')}
                    accessibilityLabel={$t({
                      id: 'Header_eatingLogButton',
                      defaultMessage: 'View Eating log',
                    })}
                    testID="Header_eatingLogButton"
                  />
                </HeaderButtons>
              ),
            })}
          />
          <Stack.Screen name="Account" component={Account} />
          <Stack.Screen name="EditLessonsLearned" component={EditLessonsLearned} />
          {registerEatingLogScreens(Stack)}
          <Stack.Screen name="MyProgress" component={MyProgress} />
          <Stack.Screen
            name="QuizSet"
            component={QuizSet}
            options={RoundedSectionNavigationOptions({
              defaultHeaderHeight,
              tintColor: 'white',
              backgroundColor: 'black',
            })}
            initialParams={{}}
          />
          <Stack.Screen
            name="ContactsPicker"
            component={ContactsPicker}
            options={() => ({
              title: $t({ id: 'ContactsPicker_title', defaultMessage: 'Contacts' }),
              headerLeft: () => <HeaderBackButton onPress={() => {}} />,
              headerRight: undefined,
            })}
          />
        </>
      ) : (
        <>
          {Auth({ $t, Screen: Stack.Screen, theme: scheme, Color })}
          <Stack.Screen
            name="CreateTestUser"
            component={CreateTestUser}
            options={{ headerShown: false }}
          />
          <Stack.Screen name="Welcome" component={Welcome} options={{ headerShown: false }} />
        </>
      )}
    </Stack.Navigator>
  );
};
