import { useMutation, useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { useCallback, useState } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { graphql } from '@oui/lib/src/graphql/tada';
import { LessonLearned } from '@oui/lib/src/types';

import { Button } from '../components/Button';
import { EditLessonsLearnedForm } from '../components/EditLessonsLearnedForm/EditLessonsLearnedForm';
import { HeaderButtons, HeaderItem } from '../components/HeaderButtons';
import { ScrollView } from '../components/ScrollView';
import { UnsavedChangesModal } from '../components/UnsavedChangesModal';
import { View } from '../components/View';
import { useI18n } from '../lib/i18n';
import { useTheme } from '../styles';
import { StackScreenProps } from '../types/navigation';

export const EditLessonsLearnedQuery = graphql(`
  query EditLessonsLearned {
    lessonLearned {
      createdAt
      session
      text
    }
  }
`);

export const SaveLessonLearnedMutation = graphql(`
  mutation SaveLessonLearned($session: String!, $text: Any!) {
    kvRespond(context: $session, key: "lessonLearned", data: $text) {
      __typename
      context
      key
      value
    }
  }
`);

export function EditLessonsLearned() {
  const safeArea = useSafeAreaInsets();
  const { $t } = useI18n();
  const { theme } = useTheme();
  const { data, refetch } = useQuery(EditLessonsLearnedQuery);
  const [saveLesson] = useMutation(SaveLessonLearnedMutation);

  const navigation = useNavigation<StackScreenProps<'EditLessonsLearned'>['navigation']>();
  const [unsavedData, setUnsavedData] = useState<LessonLearned[]>([]);

  const onSave = useCallback(async () => {
    const promises = unsavedData.map((lesson) => {
      const currentValue = data?.lessonLearned?.find((less) => less.session === lesson.session);
      return currentValue?.text !== lesson.text
        ? saveLesson({
            variables: {
              session: lesson.session,
              text: lesson.text,
            },
          })
        : Promise.resolve();
    });
    await Promise.all(promises);
    await refetch();
    setUnsavedData([]);
    setTimeout(() => {
      navigation.goBack();
    }, 0);
  }, [unsavedData, data, saveLesson, refetch, navigation]);

  const onEditLessonsLearned = useCallback((updatedLessons: LessonLearned[]) => {
    setUnsavedData(updatedLessons);
  }, []);

  navigation.setOptions({
    headerTitle: $t({
      id: 'EditLessonsLearned_editHeading',
      defaultMessage: 'Edit lessons learned',
    }),
    headerRight: () => (
      <HeaderButtons>
        <Button
          text={$t({ id: 'EditLessonsLearned_saveButton', defaultMessage: 'Save' })}
          testID="EditLessonsLearned_saveButton"
          alignSelf="flex-start"
          onPress={onSave}
          style={{ paddingHorizontal: 14 }}
        />
      </HeaderButtons>
    ),
    headerLeft: ({ tintColor }) => (
      <HeaderButtons>
        <HeaderItem
          testID="EditLessonsLearned_cancelButton"
          accessibilityLabel={$t({
            id: 'EditLessonsLearned_cancelButton',
            defaultMessage: 'Cancel',
          })}
          title=""
          onPress={() => {
            navigation.goBack();
          }}
          iconName="close"
          color={tintColor}
        />
      </HeaderButtons>
    ),
  });

  return (
    <ScrollView
      bottomOverflowColor={theme.color.gray800}
      style={{ flex: 1, backgroundColor: theme.color.gray800 }}
      contentContainerStyle={{
        paddingBottom: safeArea.bottom + 18,
        padding: 18,
      }}
      testID="EditLessonsLearned_scrollView"
    >
      <View
        style={{
          paddingRight: 9,
        }}
      >
        <EditLessonsLearnedForm
          data={unsavedData.length > 0 ? unsavedData : data?.lessonLearned ?? []}
          onEdit={onEditLessonsLearned}
        />
      </View>

      <UnsavedChangesModal
        hasUnsavedChanges={unsavedData.length > 0}
        onConfirm={({ continueNavigation }) => {
          return onSave().then(() => {
            continueNavigation();
          });
        }}
        confirmText={$t({
          id: 'EditLessonsLearned_confirmationModal_confirmButton',
          defaultMessage: 'Save',
        })}
        cancelText={$t({
          id: 'EditLessonsLearned_confirmationModal_cancelButton',
          defaultMessage: 'Discard',
        })}
        title={$t({
          id: 'EditLessonsLearned_confirmationModal_title',
          defaultMessage: 'Save changes?',
        })}
        description={$t({
          id: 'EditLessonsLearned_confirmationModal_description',
          defaultMessage: 'You’ve made changes. Would you like to save them before leaving?',
        })}
      />
    </ScrollView>
  );
}
